import { WALLET_KEYS } from "../constants/common";
import { useAccount } from "wagmi";

export const useEmbed = () => {
  const { connector: activeConnector } = useAccount();

  const connectorID = activeConnector?.id || "";

  const isEmbed = connectorID === WALLET_KEYS.LEDGER_LIVE;

  return { isEmbed: isEmbed };
};

import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { MODAL_TYPES } from "@constants/modal";
import React, { ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModalData } from "store";

function DrawerComponent({ MobileSideBar }: { MobileSideBar: ReactNode }) {
  const { modalType } = useSelector((state: any) => state.modal);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(
      updateModalData({
        modalType: "",
        isMobileView: false,
        errorMessage: "",
      })
    );
  }, [dispatch]);
  return (
    <Drawer
      isOpen={modalType === MODAL_TYPES.MOBILE_SIDEBAR_MENU_MODAL}
      placement="left"
      onClose={() => handleClose()}
      returnFocusOnClose={false}
      onOverlayClick={handleClose}
    >
      <DrawerOverlay bg="modalOverlay" />
      <DrawerContent bg="backgroundCard" overflow="auto">
        {MobileSideBar}
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerComponent;

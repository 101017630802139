import {
  Stack,
  Box,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  Flex,
  Text,
} from "@chakra-ui/react";
import { NAV_ITEMS } from "./NavData";

import UTMLink from "@components/UTMLink";
import { SideBarDataType } from "@constants/types";
import { Fragment } from "react";
import { trackEvent } from "utils";
import { LP_HEADER } from "@constants/analytics";
import BaseImage from "@components/BaseImage";

interface DesktopNavProps {
  networks: SideBarDataType[];
}

export const DesktopNav = ({ networks }: DesktopNavProps) => {
  const getData = (key: string, data: any) => {
    if (key === "networks") {
      return networks;
    } else {
      return data;
    }
  };

  const getUtmLinkClick = (navItem: SideBarDataType, event?: string) => {
    return (
      <UTMLink
        href={navItem.link ?? "#"}
        isExternal={navItem.isExternal}
        key={navItem.key}
        onClick={() => {
          trackEvent(event || navItem.eventName || LP_HEADER, {
            cta: navItem.key,
            cta_text: navItem.title,
          });
        }}
      >
        <Flex
          fontSize="16px"
          fontWeight="500"
          _hover={{
            textDecoration: "none",
            color: "#075A5A",
          }}
          color="textPrimary"
          gap={2}
        >
          {navItem.title}
          {navItem.isNew && (
            <BaseImage
              src={"/assets/sidebar/newTag.svg"}
              alt={""}
              height={24}
              width={23}
              loading="eager"
            />
          )}
        </Flex>
      </UTMLink>
    );
  };

  return (
    <Stack direction={"row"} spacing={6} alignItems="center">
      {NAV_ITEMS.map((navItem) => (
        <Fragment key={navItem.key}>
          {navItem.data || navItem.key === "networks" ? (
            <Box key={navItem.key}>
              <Popover trigger={"hover"} placement={"bottom-start"}>
                <PopoverTrigger>
                  <Text
                    p={0}
                    fontSize="16px"
                    fontWeight="500"
                    color="textPrimary"
                    _hover={{
                      textDecoration: "none",
                      color: "#075A5A",
                    }}
                    cursor="pointer"
                  >
                    {navItem.title}
                  </Text>
                </PopoverTrigger>
                <PopoverContent
                  maxW="238px"
                  p="0"
                  bg="#EAFCFC"
                  borderRadius="12px"
                >
                  <PopoverArrow bg="#EAFCFC" />
                  <PopoverBody>
                    <Flex
                      flexDir="column"
                      alignItems="flex-start"
                      bg="#EAFCFC"
                      p="24px 24px"
                      border="1px #A1B6BA"
                      gap="24px"
                    >
                      {getData(navItem.key, navItem.data).map(
                        (data: SideBarDataType) => (
                          <Fragment key={data.key}>
                            {getUtmLinkClick(data, navItem.eventName)}
                          </Fragment>
                        )
                      )}
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          ) : (
            <Fragment key={navItem.key}>
              {getUtmLinkClick(navItem, navItem.eventName)}
            </Fragment>
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

import { Link, LinkProps } from "@chakra-ui/react";
import { useUtmLink } from "@hooks/useUtm";
import NextLink from "next/link";

function UTMLink(props: Readonly<LinkProps>) {
  const { href, isExternal, ...rest } = props;
  const { isFullUrl, href: finalHref } = useUtmLink(href ?? "/");

  return (
    <Link
      {...rest}
      href={finalHref}
      as={isFullUrl ? "a" : NextLink}
      isExternal={isExternal}
      textDecor="none"
      _hover={{
        textDecor: "none",
      }}
    />
  );
}

export default UTMLink;

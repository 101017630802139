import {
  Flex,
  Button,
  Collapse,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import UTMLink from "@components/UTMLink";
import React, { useCallback } from "react";
import { DesktopNav } from "./DesktopNav";
import SidebarNav from "./SidebarNav";
import { FiMenu } from "react-icons/fi";
import { MODAL_TYPES } from "@constants/modal";
import { updateModalData } from "store";
import { useDispatch } from "react-redux";
import DrawerComponent from "@components/Drawer";
import useDappConfig from "@hooks/useDappConfig";
import { SideBarDataType } from "@constants/types";
import { trackEvent } from "utils";
import { LP_RESTAKE } from "@constants/analytics";

export default function HeaderNav() {
  const { isOpen } = useDisclosure();
  const dispatch = useDispatch();

  const handleSidebarMenuClick = useCallback(() => {
    dispatch(
      updateModalData({
        modalType: MODAL_TYPES.MOBILE_SIDEBAR_MENU_MODAL,
      })
    );
  }, [dispatch]);

  const { restakeEnabledChains } = useDappConfig();

  const networks: SideBarDataType[] = restakeEnabledChains.map((chain) => {
    return {
      title: chain.name,
      key: chain.id.toString(),
      link: `/defi?chain=${chain.id}`,
      isExternal: true,
    };
  });

  return (
    <Flex
      justifyContent="space-between"
      p={{ base: "16px", md: "24px 40px" }}
      alignItems="center"
      maxW="1512px"
      w="full"
    >
      <Flex display={{ base: "flex", md: "none" }}>
        <IconButton
          display={{ base: "inherit", xl: "none" }}
          onClick={() => handleSidebarMenuClick()}
          aria-label="open menu"
          h="24px"
          w="24x"
          bg="transperent"
          color="textPrimary"
          variant="unstyled"
          icon={<FiMenu height="24px" width="24px" />}
        />
        <Flex pl="1rem">
          <BaseImage
            src="/assets/header/kelp-icon.svg"
            alt="kelp"
            height={24}
            width={19}
            loading="eager"
          />
        </Flex>
      </Flex>
      <Flex display={{ base: "none", md: "flex" }}>
        <UTMLink href="/">
          <BaseImage
            src="/assets/kelp.svg"
            alt="kelp"
            height={40}
            width={106}
            loading="eager"
          />
        </UTMLink>
      </Flex>

      <Flex flex={1} />
      <Flex display={{ base: "none", md: "flex" }} mr={10}>
        <DesktopNav networks={networks} />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <DrawerComponent MobileSideBar={<SidebarNav networks={networks} />} />
      </Collapse>

      <UTMLink
        href="/restake"
        _hover={{
          borderBottom: "none",
        }}
        onClick={() => {
          trackEvent(LP_RESTAKE, {
            triggerer: "header",
          });
        }}
      >
        <Button
          variant="unstyled"
          p={{ base: "8px 36px", md: "16px 64px" }}
          border="1px solid"
          borderRadius="16px"
          h={{ base: "42px", md: "56px" }}
          fontSize="14px"
          fontWeight="600"
          lineHeight="24px"
        >
          Restake now
        </Button>
      </UTMLink>
    </Flex>
  );
}

import {
  LP_ABOUT,
  LP_AVS,
  LP_INSIGHTS,
  LP_NETWORKS,
  LP_PRODUCTS,
} from "@constants/analytics";
import { SideBarDataType } from "@constants/types";

export const NAV_ITEMS: SideBarDataType[] = [
  {
    title: "Products",
    key: "products",
    eventName: LP_PRODUCTS,
    data: [
      {
        title: "Restake",
        key: "restake",
        link: "/restake",
        isExternal: false,
      },
      {
        title: "Gain",
        key: "gain",
        link: "/gain",
        isExternal: false,
        isNew: true,
      },
      {
        title: "Institutions",
        key: "institutions",
        link: "/institutions",
        isExternal: true,
      },
    ],
  },
  {
    title: "Networks",
    key: "networks",
    eventName: LP_NETWORKS,
  },
  {
    title: "AVS",
    key: "avs",
    link: "/avs",
    isExternal: true,
    eventName: LP_AVS,
  },
  {
    title: "About",
    key: "about",
    link: "https://kelp.gitbook.io/kelp/",
    isExternal: true,
    eventName: LP_ABOUT,
  },
  {
    title: "Insights",
    key: "insights",
    eventName: LP_INSIGHTS,
    data: [
      {
        title: "Docs",
        key: "docs",
        link: "https://kelp.gitbook.io/kelp/technical-architecture/",
        isExternal: true,
      },
      {
        title: "Litepaper",
        key: "litepaper",
        link: "https://kelpdao.xyz/litepapers/rsETHLitepaper.pdf",
        isExternal: true,
      },
      {
        title: "Resources",
        key: "resources",
        link: "/insights/resources",
        isExternal: false,
      },
      {
        title: "Blog",
        key: "blog",
        link: "https://blog.kelpdao.xyz/",
        isExternal: true,
      },
    ],
  },
];

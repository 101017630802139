import { useRouter } from "next/router";
import { Fragment, useCallback } from "react";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  BoxProps,
  Center,
  Flex,
  Text,
  AccordionPanel,
} from "@chakra-ui/react";

import UTMLink from "@components/UTMLink";
import BaseImage from "@components/BaseImage";
import { SideBarDataType } from "@constants/types";
import { NAV_ITEMS } from "./NavData";
import { trackEvent } from "utils";
import { LP_HEADER } from "@constants/analytics";

interface SidebarProps extends BoxProps {
  networks: SideBarDataType[];
}

const SidebarNav = ({ networks, ...rest }: SidebarProps) => {
  const router = useRouter();

  const getData = (key: string, data: any) => {
    if (key === "networks") {
      return networks;
    } else {
      return data;
    }
  };

  const getSidebarItemData = useCallback(
    (option: SideBarDataType) => {
      return (
        <AccordionItem border="none">
          <AccordionButton
            p="0"
            width="100%"
            justifyContent="space-between"
            _hover={{
              bg: "inherit",
            }}
            _active={{
              bg: "inherit",
            }}
          >
            <Text
              color="textSecondary"
              fontWeight="500"
              fontSize="16px"
              lineHeight="24px"
              p={3}
            >
              {option.title}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} pt={0} pr={0}>
            <Flex
              flexDir="column"
              alignItems="flex-start"
              bg="#EAFCFC"
              p="24px 24px"
              border="1px #A1B6BA"
              gap="24px"
              borderRadius="12px"
            >
              {getData(option.key, option.data).map((data: SideBarDataType) => (
                <UTMLink
                  href={data.link ?? "#"}
                  isExternal={data.isExternal}
                  key={data.key}
                  onClick={() => {
                    trackEvent(
                      option.eventName || data.eventName || LP_HEADER,
                      {
                        cta: data.key,
                        cta_text: data.title,
                      }
                    );
                  }}
                >
                  <Flex
                    color="textSecondary"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="16px"
                    _hover={{
                      textDecoration: "none",
                      color: "#075A5A",
                    }}
                    gap={2}
                  >
                    {data.title}
                    {data.isNew && (
                      <BaseImage
                        src={"/assets/sidebar/newTag.svg"}
                        alt={""}
                        height={24}
                        width={23}
                        loading="eager"
                      />
                    )}
                  </Flex>
                </UTMLink>
              ))}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      );
    },
    [networks]
  );

  const getSideBarItem = useCallback(
    (option: SideBarDataType) => {
      const { key, title, isDisabled, link, isExternal } = option;

      const [, activeKey] = router?.pathname?.split("/") || "";

      return (
        <UTMLink
          key={key}
          href={link}
          isExternal={isExternal}
          onClick={() => {
            trackEvent(option.eventName || LP_HEADER, {
              cta: option.key,
              cta_text: option.title,
            });
          }}
        >
          <Flex
            p="12px 16px 12px 12px"
            borderRadius="4px"
            gap="12px"
            bg={activeKey === key ? "backgroundDivider" : "inherit"}
            alignItems="flex-start"
            cursor={isDisabled ? "not-allowed" : "pointer"}
          >
            <Flex flexDir="column">
              <Text
                color={activeKey === key ? "textPrimary" : "textSecondary"}
                fontWeight="500"
                fontSize="16px"
                lineHeight="24px"
              >
                {title}
              </Text>
            </Flex>
          </Flex>
        </UTMLink>
      );
    },
    [router]
  );

  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      gap={{ base: "40px", xl: "64px" }}
      p={{ base: "32px 24px 0 40px", xl: "0 28px 0 40px" }}
      {...rest}
      position="fixed"
      w={{ base: "full", md: "240px" }}
    >
      <UTMLink href="/">
        <Center h="40px" justifyContent="flex-start" cursor="pointer">
          <BaseImage
            src="/assets/kelp.svg"
            alt="kelp"
            height={40}
            width={106}
            loading="eager"
          />
        </Center>
      </UTMLink>

      <Accordion
        gap="12px"
        // w="172px"
        mt={{ base: "0px", xl: "64px" }}
        allowToggle
        w={"100%"}
      >
        {NAV_ITEMS.map((option) => {
          return (
            <Fragment key={option.key}>
              {option.data || option.key === "networks"
                ? getSidebarItemData(option)
                : getSideBarItem(option)}
            </Fragment>
          );
        })}
      </Accordion>
    </Flex>
  );
};

export default SidebarNav;
